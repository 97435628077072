import CareerDetail from "../../features/career_detail";

export default (props: Object) => {
  return (
    <CareerDetail
      {...props}
      locales={{
        it: {
          title: "Mobile app developer",
          seoDescription:
            "Essere lofter significa curiosità, ambizione e passione. Lavoriamo per portare innovazione nel mondo del software e dei prodotti digitali. Ti va di unirti a noi?",
          roles: [
            "Imparerai a costruire un progetto digitale in ogni sua parte",
            "Svilupperai componenti per migliorare l'esperienza di interazione dell'utente",
            "Dividerai la business logic dalla parte presentazionale",
            "Scriverai buon codice organizzato",
            "Creerai i prodotti digitali del futuro",
            "Farai tanta ricerca!",
          ],
          prerequisites: [
            "Passione per prodotti digitali",
            "Esperienza di sviluppo in Flutter, Java, Kotlin o Swift",
            "Buona conoscenza dell’inglese",
          ],
          yearsOfExperience: 2,
        },
      }}
    />
  );
};
